import React from "react";
import * as Yup from "yup";
import {
  Field,
  ErrorMessage,
  Form,
  FieldArray,
  useFormik,
  Formik,
} from "formik";
import { useEffect } from "react";
import ModeContext from "../../context/mode-context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { rxOpdApi } from "../../utils/api/api";
import { RX_OPD_ENDPOINTS } from "../../utils/api/apiEndPoints";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SendPaymentLink from "./SendPaymentLink";
import Cashpaymentlink from "./Cashpaymentlink";
import Toast from "../ui/Toast";
import { Spinner } from "react-bootstrap";
import { useMediaQuery } from "@material-ui/core";


const DisplayOrder = () => {
  const { mode } = useContext(ModeContext);
  const [data, setData] = useState([]);
  //const {line_items} = data
  const [searchParams, setSearchParams] = useSearchParams();
  const hospitalId = searchParams.get("hospitalId");
  const category = searchParams.get("category");
  const orderId = searchParams.get("orderId");
  const [cashpaymentlink, setCashPaymentlink] = useState(false);
  const [selectedMode, setSelectedMode] = useState(""); // State to track the selected payment mode
  const [modal, setModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState();
  const [toastMessage, setToastMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width:710px)");

  const [initialValues, setInitialValues] = useState({
    order_id: ``,
    customer_name: ``,
    country_code: "+91",
    phone: ``,
    email: ``,
    gstin: ``,
    address: ``,

    line_items: [
      {
        rate: "23",
        details: "123",
        quantity: "15",
        discount: "5",
        gst_percentage: "5",
        calc_amount: "",
      },
    ],
  });

  const navigate = useNavigate();
  const handleCloseButtonClick = async (params) => {
    navigate("/app/billing/Orders");
  };

  const deleteOrder = async (order_id) => {
    const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
    const key = userModeKey[`${mode}_key`];
    const secret = userModeKey[`${mode}_secret`];
    // console.log("hi",order_id);
    try {
      rxOpdApi.setAuthHeaders(key, secret);
      const url = `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.DELETE_SERVICE_ORDER}/${hospitalId}/${category}/${order_id}`;

      const res = await rxOpdApi.delete(url);

      if (res) {
        setToastMessage(res.data.message);
        setShowToast("true");

        setToastType("success");
        //console.log(res.data.message)
      } else {
        throw new Error("Something went wrong. Please try later.");
      }
    } catch (error) {}
    handleCloseButtonClick();
  };

  useEffect(() => {
    const OrderData = async () => {
      const userModeKey = JSON.parse(localStorage.getItem("usr_keys"))[mode];
      const key = userModeKey[`${mode}_key`];
      const secret = userModeKey[`${mode}_secret`];
      setIsLoading(true);

      try {
        rxOpdApi.setAuthHeaders(key, secret);
        const url = `${RX_OPD_ENDPOINTS.HOSPITAL.OPD.ORDERS_DETAILS}/${hospitalId}/${category}/${orderId}`;

        const res = await rxOpdApi.get(url);

        if (res) {
          setData(res.data);
          setInitialValues(res.data);
        } else {
          throw new Error("Something went wrong. Please try later.");
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    OrderData();
  }, []);

  // console.log(initialValues,data,"data retrived")

  const validationSchema = Yup.object({
    name: Yup.string().required("required"),
    countryCode: Yup.string().required("required"),
    mobile: Yup.number()
      .required("required")
      .min(10, "not a valid length")
      .max(10, "exceeds 10 digits"),
    // email:Yup.email('Invalid Email'),
    gst: Yup.string("REQUIRED"),
    address: Yup.string("required"),
    details: Yup.string("required"),
    quantity: Yup.number("required"),
    gst_percentage: Yup.number("required")
      .min(0, "required")
      .max(28, "required"),
    calc_amount: Yup.number("required").min(1, "total cannot be Zero"),
  });

  const onSubmit = (values) => {};

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <button
        style={{
          float: "right",
          fontSize: "40px",
          width: "50px",
          border: "none",
          backgroundColor: "white",
          color: "black",
        }}
        type="button"
        class="close"
        onClick={handleCloseButtonClick}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <h5
              style={{
                width: "100%",
                textAlign: "center",
                color: "grey",
                marginBottom: "20px",
              }}
            >
              {" "}
              ORDER {data.status !== "CREATED" && (
                <span>({data.status})</span>
              )}{" "}
            </h5>

            {/* First ROW */}
            <div
              className="csname"
              style={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "610px",
              }}
            >
              <label htmlFor="customer_name" />
              <Field
                style={{
                  height: "35px",
                  paddingLeft: "10px",
                  width: "max(90%,255px)",
                  border: "2px solid lightblue",
                }}
                type="text"
                name="customer_name"
                value={initialValues.customer_name}
                id="customer_name"
                placeholder="Name"
              />
              <ErrorMessage name="customer_name" />
            </div>
            {/* Second Row */}
            <div className="Header2">
              <label htmlFor="country_code"></label>
              <Field
                style={{
                  width: "min(30%,60px)",
                  border: "2px solid lightblue",
                  height: "35px",
                  paddingLeft: "5px",
                  backgroundColor: "lightgrey",
                }}
                type="text"
                name="country_code"
                id="country_code"
                value="+91"
                placeholder="Country code"
              />
              <ErrorMessage name="country_code" />

              <label htmlFor="phone"></label>
              <Field
                style={{
                  width: "min(45%,250px)",
                  paddingLeft: "5px",
                  border: "2px solid lightblue",
                  height: "35px",
                }}
                type="number"
                name="phone"
                id="phone"
                value={initialValues.phone}
                placeholder="Your 10 Digit Mobile Number"
              />
              <ErrorMessage name="phone" />

              <label htmlFor="email"></label>
              <Field
                style={{
                  width: "min(45%,250px)",
                  paddingLeft: "5px",
                  border: "2px solid lightblue",
                  height: "36px",
                }}
                type="email"
                name="email"
                id="email"
                value={initialValues.email}
                placeholder="Your Email"
              />
              <ErrorMessage name="email" />
            </div>

            {/* Third Row */}
            <div className="Header2">
              <label htmlFor="gstin"></label>
              <Field
                style={{
                  width: "min(20%,200px)",
                  marginRight: "3px",
                  border: "2px solid lightblue",
                  paddingLeft: "5px",
                }}
                type="text"
                name="gstin"
                id="gstin"
                value={initialValues.gstin}
                placeholder=""
              />
              <ErrorMessage name="gstin" />

              <label htmlFor="address"></label>
              <Field
                style={{
                  width: "min(80%,480px)",
                  border: "2px solid lightblue",
                  paddingLeft: "5px",
                }}
                as="textarea"
                name="address"
                id="address"
                value={initialValues.address}
                placeholder="Address"
              />
              <ErrorMessage name="address" />
            </div>

            <h5
              style={{
                width: "900px",
                textAlign: "left",
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "30px",
              }}
            >
              {" "}
              Order Details
            </h5>

            {/* Heading of ARRAY */}
            <div className="orderHeader">
              <h5
                style={{
                  width: "max(100px,100%)",
                  marginBottom: "0",
                  height: "60px",
                  paddingTop: "10px",
                  backgroundColor: "#efecec",
                  color: "grey",
                  border: "2px solid darkgrey",
                }}
              >
                {" "}
                Item Details
              </h5>
              <h5
                style={{
                  width: "max(70px,70%)",
                  marginBottom: 0,
                  height: "60px",
                  paddingTop: "10px",
                  backgroundColor: "#efecec",
                  color: "grey",
                  border: "2px solid darkgrey",
                }}
              >
                {" "}
                Quantity
              </h5>
              <h5
                style={{
                  width: "max(70px,70%)",
                  marginBottom: 0,
                  height: "60px",
                  paddingTop: "10px",
                  backgroundColor: "#efecec",
                  color: "grey",
                  border: "2px solid darkgrey",
                }}
              >
                Rate
              </h5>
              <h5
                style={{
                  width: "max(70px,70%)",
                  marginBottom: 0,
                  height: "60px",
                  paddingTop: "10px",
                  backgroundColor: "#efecec",
                  color: "grey",
                  border: "2px solid darkgrey",
                }}
              >
                Discount
              </h5>
              <h5
                style={{
                  width: "max(70px,70%)",
                  marginBottom: 0,
                  height: "60px",
                  paddingTop: "10px",
                  backgroundColor: "#efecec",
                  color: "grey",
                  border: "2px solid darkgrey",
                }}
              >
                GST
              </h5>
              <h5
                style={{
                  width: "max(70px,70%)",
                  marginBottom: "0px",
                  height: "60px",
                  marginRight: "10px",
                  paddingTop: "10px",
                  backgroundColor: "#efecec",
                  color: "grey",
                  border: "2px solid darkgrey",
                }}
              >
                Amount
              </h5>
            </div>

            <FieldArray
              value="line_items"
              id="line_items"
              className="inputwidth"
            >
              {(fieldArrayprops) => {
                // console.log("Fieldarrayprops", FieldArrayprops)
                const { push, form } = fieldArrayprops;
                const { values } = form;

                const { line_items } = values;
                // console.log(form,"indexline");
                return (
                  <div>
                    {line_items.map(
                      (item, index) => (
                        () => {
                          // console.log(index,"index");
                          push({
                            rate: "",
                            details: "",
                            quantity: "",
                            discount: "0",
                            gst_percentage: "0",
                            calc_amount: "",
                          });
                        },
                        (
                          <div
                            key={index}
                            className="csname"
                            style={{ maxWidth: "1105px", display: "flex" }}
                          >
                            <Field
                              style={{
                                width:  isMobile ? "max(81px,93%)"  : "max(70px,93%)",
                                marginLeft: "30px",
                                marginTop: "0px",
                                height: "60px",
                                border: "2px solid grey",
                                textAlign: "center",
                              }}
                              name={`line_items[${index}].details`}
                              value={
                                initialValues.line_items[`${index}`].details
                              }
                            />
                            <Field
                              style={{
                                width: isMobile ? "max(59px,65%)" : "max(70px, 65%)",
                                height: "60px",
                                textAlign: "center",
                                border: "2px solid grey",
                              }}
                              value={
                                initialValues.line_items[`${index}`].quantity
                              }
                              name={`line_items[${index}].quantity`}
                            />
                            <Field
                              style={{
                                width: isMobile ? "max(59px,65%)" : "max(70px, 65%)",
                                height: "60px",
                                border: "2px solid grey",
                                textAlign: "center",
                              }}
                              value={initialValues.line_items[`${index}`].rate}
                              name={`line_items[${index}].rate`}
                              placeholder="rate"
                            />

                            <Field
                              style={{
                                width: isMobile ? "max(59px,65%)" : "max(70px, 65%)",
                                height: "60px",
                                border: "2px solid grey",
                                textAlign: "center",
                              }}
                              name={`line_items[${index}].discount`}
                              value={
                                initialValues.line_items[`${index}`].discount
                              }
                            />
<Field
  style={{
    width: isMobile ? "max(45px,64%)" : "max(70px, 65%)",
    height: "60px",
    border: "2px solid grey",
    textAlign: "center",
    backgroundColor: initialValues.line_items[`${index}`].gst_percentage ? "white" : "#f0f0f0",
  }}
  value={initialValues.line_items[`${index}`].gst_percentage}
  name={`line_items[${index}].gst`}
  readOnly={!!initialValues.line_items[`${index}`].gst_percentage}
/>
                            <Field
                              style={{
                                width: isMobile ? "max(45px,65%)" : "max(70px, 65%)",
                                marginRight: "10px",
                                height: "60px",
                                border: "2px solid grey",
                                textAlign: "center",
                              }}
                              name={`line_items[${index}].calc_amount`}
                              value={
                                initialValues.line_items[`${index}`].calc_amount
                              }
                              placeholder="Total"
                            />
                          </div>
                        )
                      )
                    )}
                  </div>
                );
              }}
            </FieldArray>

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "30px",
                maxWidth: "1095px",
              }}
            >
              <div
                style={{
                  width: "210px",
                  height: "100px",
                  backgroundColor: "#efecec",
                  paddingTop: "10px",
                  textAlign: "left",
                  borderRadius: "5px",
                }}
              >
                <div style={{ marginLeft: "10px" }}>
                  Sub Total{" "}
                  <span style={{ float: "right", marginRight: "15px" }}>
                    {initialValues.sub_total}
                  </span>{" "}
                </div>
                <div style={{ marginLeft: "10px" }}>
                  Tax(Gst){" "}
                  <span style={{ float: "right", marginRight: "15px" }}>
                    {" "}
                    {initialValues.total_tax}
                  </span>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <b>TOTAL(INR)</b>{" "}
                  <span style={{ float: "right", marginRight: "15px" }}>
                    {initialValues.total}
                  </span>{" "}
                </div>
              </div>
            </div>

            {/* this for payment option */}

            <div
              style={{ display: "flex", alignItems: "center", margin: "30px" }}
            >
              <div style={{ marginRight: "10px" }}>
                <b>Payment Mode: {data.status === "PAID" && data.type}</b>{" "}
              </div>
              {data.type === "ONLINE" && (
                <div>
                  <input
                    type="radio"
                    id="onlinePayment"
                    name="paymentMode"
                    checked={true}
                    style={{ margin: "10px" }}
                  />
                  <label
                    htmlFor="onlinePayment"
                    style={{ marginRight: "10px", width: "100px" }}
                  >
                    Online{" "}
                  </label>
                </div>
              )}

              {data.type === "CASH" && (
                <div>
                  <input
                    type="radio"
                    id="cashPayment"
                    name="paymentMode"
                    style={{ margin: "10px" }}
                    checked={true}
                  />

                  <label htmlFor="cashPayment">Cash </label>
                </div>
              )}
            </div>
            {/* this is for buttons  */}

            <div className="d-flex justify-content-center ">
              {data.show_cash_payment_button && (
                <button
                  style={{
                    width: "170px",
                    backgroundColor: "#009698",
                    color: "white",
                  }}
                  className="btn shadow m-4"
                  onClick={() => setCashPaymentlink(true)}
                >
                  Cash Payment{" "}
                </button>
              )}
              {data.show_payment_link_button && (
                <button
                  style={{
                    width: "170px",
                    backgroundColor: "#009698",
                    color: "white",
                  }}
                  className="btn  shadow m-4"
                  onClick={() => setModal(!modal)}
                >
                  Send Payment Link
                </button>
              )}

              {data.show_cancel_order_button && (
                <button
                  style={{ width: "150px" }}
                  className="btn btn-danger shadow m-4"
                  onClick={() => {
                    deleteOrder(data.order_id);
                  }}
                >
                  Cancel Order
                </button>
              )}
            </div>

            {modal && (
              <SendPaymentLink
                customerName={data.customer_name}
                email={data.email}
                mobile={data.phone}
                orderid={data.order_id}
                category={category}
              />
            )}
            {cashpaymentlink && (
              <Cashpaymentlink
                amount={initialValues.total}
                orderid={data.order_id}
                category={category}
                hos_id={hospitalId}
              />
            )}
          </Form>
        )}
      </Formik>

      {/* {console.log("toastType",toastMessage,toastType,showToast)
} */}
      {showToast && (
        <Toast
          type={toastType}
          show={showToast}
          handleToastClose={setShowToast}
          autohide={true}
          autohideDelay={3000}
        >
          {toastMessage}
        </Toast>
      )}
      {!showToast && toastType === "success" && handleCloseButtonClick()}
    </>
  );
};
export default DisplayOrder;
